.banner {
  width: 65%;
  z-index: 1;
  margin: auto;
}

.slide {
  width: 80%;
}

.image {
  width: 100%;
  object-fit: cover;
  /* min-height: 700px; */
}
.imageP {
  width: 50px;
  height: 50px;
  object-fit: cover;
  /* min-height: 700px; */
}

.slick-initialized {
  width: 95%;
  margin: auto;
}

.uploadButtonContainer {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.uploadButton {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Estilos para el modal */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0px 10px;

}

.modalContent span {
  font-weight: 500;
}

.modalContent h2 {
  margin-bottom: 10px;
}

.modalContent input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.modalContent button {
    font-size: 15px;
    font-weight: bold;
    padding: 10px 30px;
    border-radius: 50px;
    cursor: pointer;
    color: #4caf50;
    border: 1px solid #4caf50;
    background-color: transparent;
    transition: color 0.3s ease-in-out;
    position: relative;
    overflow: hidden;

  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.modalContent button:last-child {
  background-color: #f44336;
}

.conteinerButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.divButton .button {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 50px;
  cursor: pointer;
  color: #3cbbed;
  border: 1px solid #3cbbed;
  background-color: transparent;
  transition: color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.divButton .button::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  border-radius: 50px;
  background-color: #3cbbed;
  z-index: -1;
  transition: left 0.3s ease-in-out;
}

.divButton .button:hover {
  color: white;
}

.divButton .button:hover::before {
  left: 0;
}

.divImgDel {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px 0px;
}

.divDel {
  display: flex;
  flex-direction: column;
  align-items: center;
}




@media only screen and (max-width: 750px) {
  .banner{
    width: 100%;
  }
  .slick-initialized {
    width: 100%;
  }
}