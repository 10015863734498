.divSearchedProduct{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.h1{
    color: #3CBBED;
}

.divContenido{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0px;
    max-width: 1000px;
}

.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.span{
    text-align: center;
    margin: 30px auto;
    font-size: 30px;
}

@media screen and (max-width: 450px) {
    .h1{
        font-size: 20px;
    }

    .divContenido{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px 0px;
        max-width: 1000px;
    }
  }
