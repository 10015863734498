.contenedorMayor {
    background: #15214a;
    height: 100vh;
    display: flex;
    font-family: "Gotham", sans-serif;
    border: 10px solid #15214a;
    margin: 0;
    justify-content: space-evenly;
}

.contenedor {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 20px;
}

.divImagen {
    width: 45%; /* Ancho del 100% para ocupar toda la página */
}

.imgder {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.titulo {
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    font-size: 20px;
    padding: 10px;
    color: #15214a;
}

.logo {
    width: 50%;
}

.formulario{
    max-width: 400px;
    width: 100%;
}

/* FORM */
.form input {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-family: "Gotham", sans-serif;
    font-size: 15px;
    align-items: center;
    gap: 12px;
    border-radius: 20px;
    background: transparent;
    border: 1px solid #3cbbed;
}
.form input::placeholder {
    font-size: 12.5px;
}
.form input:focus {
    outline: none;
    border: 2.5px solid #3cbbed;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
}
.formbutton {
    border: none;
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    color: white;
    position: relative;
    transition: 0.2s;
    border: 1px solid #3cbbed;
    color: #3cbbed;
    background-color: transparent;
    transition: 0.3s;
    overflow: hidden;
}
.formbutton:hover {
    color: white;
    background-color: #3cbbed;
}

/* BOTON CLOSE */

.x {
    border: none;
    font-family: "Gotham", sans-serif;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    position: relative;
    transition: 0.2s;
    background-color: transparent;
    transition: 0.3s;
    overflow: hidden;
}
.x:hover {
    color: #15214a;
}


/* Password  */

.passwordcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    text-align: center;
    width: 100%;

    display: flex;
    width: 100%;
    height: 100%;
    font-family: "Gotham", sans-serif;
    font-size: 12.5px;
    align-items: center;
    border-radius: 20px;
    background: transparent;
    border: 1px solid #3cbbed;

    &:focus{
        outline: none;
        border: 2.5px solid #3cbbed;
    }

    .img {
        width: 20px;
        height: 20px;
    }
}
.passwordcontainer:focus{
    outline: none;
    border: 2.5px solid #3cbbed;
}

.passwordcontainer input {
    display: flex;
    padding: 10px;
    width: 100%;
    align-items: center;
    border-radius: 20px;
    background: transparent;
    border: none;
    font-family: "Gotham", sans-serif;

}
.passwordcontainer input:focus{
    outline: none;
    border: none;
}

.passwordtoggle {
    margin: 2px 5px 0px 0px;
    cursor: pointer;
}

.divmensaje {
    background-color: #3cbbed;
    color: white;
    margin-top: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    padding: 0px 10px 10px 10px;
    font-family: "Gotham", sans-serif;
    gap: 5px;
    border-radius: 5px;
    .x {
        left: 35%;
    }
    .span {
        font-family: "Gotham", sans-serif;
        padding: 20px 0px 10px 0px;
        font-weight: bolder;
    }

}

.divmensajePass {
    background-color: #3cbbed;
    color: white;
    margin-top: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    padding: 0px 10px 10px 10px;
    font-family: "Gotham", sans-serif;
    gap: 5px;
    border-radius: 5px;
    .x {
        left: 35%;
    }
    .span {
        font-family: "Gotham", sans-serif;
        padding: 20px 0px 10px 0px;
        font-weight: bolder;
    }

}


@media (max-width: 768px) {

    .contenedorMayor {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contenedor {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 10px;
        padding: 20px;
    }

    .contenedor form {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }

    .divImagen {
        width: 100%;
        border: none;
        /* Esto asegura que el borde esté incluido en el ancho total */
        height: 20%;
        margin-top: auto;
        display: none;
    }

    /* FORM */
    .form input {
        display: flex;
        height: 100%;
        padding: 10px;
        font-family: "Gotham", sans-serif;
        font-size: 12.5px;
        align-items: center;
        gap: 5px;
        border-radius: 20px;
        background: transparent;
        border: 1px solid #3cbbed;
    }

    .formbutton {
        width: 100%;
    }

    /* Password  */

    .passwordcontainer {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .passwordcontainer input {
        display: flex;
        padding: 10px;
        width: 100%;
        align-items: center;
        border-radius: 20px;
        background: transparent;
        border: none;
        font-family: "Gotham", sans-serif;
    }

    .img {
        width: 20px;
        height: 20px;
    }

    .logo {
        width: 70%;
    }
}