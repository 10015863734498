.div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.divComplementario {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.logo {
    margin-top: 10px;
    width: 15%;
}

.titulo {
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    color: #15214a;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;
}

.divCampo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;

    .label {
        font-family: "Gotham", sans-serif;
        color: #15214a;
        font-size: 15px;
        font-weight: bolder;
        padding-left: 5px;
    }
}

.divInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    gap: 1px;

    .p {
        font-family: "Gotham", sans-serif;
        color: #15214a;
        font-weight: bold;
        font-size: 15px;
        margin-top: 1px;
    }

    .input {
        width: 400px;
        height: auto;
        padding: 4px;
        font-family: "Gotham", sans-serif;
        font-size: 17px;
        align-items: center;
        border-radius: 10px;
        background: transparent;
        border: 0.5px solid #3cbbed;
        text-align: center;
    }

    .input::placeholder {
        font-size: 12.5px;
    }

    .input:focus {
        outline: none;
        border: 2px solid #3cbbed;
    }

    .select {
        font-family: "Gotham", sans-serif;
        background-color: white;
        width: 400px;
        height: auto;
        padding: 4px;
        color: #2f92b9;
        text-align: center;
        font-size: 17px;
        border: solid #3cbbed 1.8px;
        border-radius: 10px;
    }

    .select:focus {
        background-color: #3cbbed;
        color: white;
        outline: none;
        border-color: #15214a;
    }
}



.inputext {
    width: 400px;
    height: auto;
    padding: 4px;
    font-family: "Gotham", sans-serif;
    font-size: 15px;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    background: transparent;
    border: 0.5px solid #3cbbed;
    text-align: center;
    font-weight: bold;
}

.inputext::placeholder {
    font-size: 12.5px;
}

.inputext:focus {
    outline: none;
    border: 2px solid #3cbbed;
}


.divVistaPrevia {
    width: 400px;
    border: 2px solid #15214a;
    height: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
}

.divImagenes {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.miniatura {
    width: 100px;
    height: 100px;
    padding: 5px;
}


.mensajeProducto {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 15px;

}

.mensajeCreated {
    font-family: "Gotham", sans-serif;
    color: white;
    background-color: rgb(74, 134, 74);
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;

}

.mensajeNoCreated {
    font-family: "Gotham", sans-serif;
    color: white;
    background-color: rgb(134, 74, 74);
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    align-content: center;

}

.formbutton {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.formbutton:hover {
    color: white;
}

.formbutton::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #15214a;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.formbutton:hover::before {
    width: 100%;
    opacity: 1;
}


.formbuttonClean {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.formbuttonClean:hover {
    color: white;
}

.formbuttonClean::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #15214a;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.formbuttonClean:hover::before {
    width: 100%;
    opacity: 1;
}

.aviso {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.botonYes {
    border: none;
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.botonYes:hover {
    color: white;
}

.botonYes::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #2954e2;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.botonYes:hover::before {
    width: 100%;
    opacity: 1;
}

.botonNo {
    border: none;
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    border: 2px solid #15214a;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.botonNo:hover {
    color: white;
}

.botonNo::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #01165a;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.botonNo:hover::before {
    width: 100%;
    opacity: 1;
}

.mensajeAviso {
    font-size: 13.5px;
    font-family: "Gotham", sans-serif;
}


.errorMenssage {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    gap: 1px;
    align-content: flex-start;
}

.errorMenssagePass {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    justify-items: left;
    align-content: flex-start;
    gap: 1px;
}

.errorText {
    font-size: 10px;
    margin-top: 5px;
    padding: 0px 4px 0px 4px;
    transition: opacity 0.3s ease;
    font-family: "Gotham", sans-serif;
    color: red;

}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}


/* Passowrd */
.passOn {
    color: green;
}

.passOff {
    color: red;
}


.mensaje {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    justify-items: center;
    text-align: center;
    padding: 10px;

    .positivo {
        font-family: "Gotham", sans-serif;
        color: white;
        background-color: rgb(74, 134, 74);
        padding: 5px;
        border-radius: 10px;
        align-items: center;
        align-content: center;

    }

    .negativo {
        font-family: "Gotham", sans-serif;
        color: white;
        background-color: rgb(134, 74, 74);
        padding: 5px;
        border-radius: 10px;
        align-items: center;
        align-content: center;

    }
}

.contraseña {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.formbuttonBack {
    border: none;
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #15214a;
    background-color: transparent;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.formbuttonBack:hover {
    color: white;
}

.formbuttonBack::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #15214a;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.formbuttonBack:hover::before {
    width: 100%;
    opacity: 1;
}

@media (max-width: 768px) {
    .divComplementario{
        justify-content: center;
    }

    .div{
        padding-top: 90px;
    }

    .divDatos{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .logo {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 65%;
    }

    .titulo {
        font-family: "Gotham", sans-serif;
        font-weight: bold;
        font-size: 18px;
        color: #15214a;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }

    .form {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .divCampo {
        width: 90%;
        gap: 5px;
    }

    .divInput {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1px;

        .p {
            font-family: "Gotham", sans-serif;
            color: #15214a;
            font-weight: bold;
            font-size: 15px;
            margin-top: 1px;
        }

        .input {
            width: 90%;
            height: auto;
            padding: 4px;
            font-family: "Gotham", sans-serif;
            font-size: 17px;
            align-items: center;
            border-radius: 10px;
            background: transparent;
            border: 0.5px solid #3cbbed;
            text-align: center;
        }

        .input::placeholder {
            font-size: 12.5px;
        }

        .input:focus {
            outline: none;
            border: 2px solid #3cbbed;
        }

        .select {
            font-family: "Gotham", sans-serif;
            background-color: white;
            width: 400px;
            height: auto;
            padding: 4px;
            color: #2f92b9;
            text-align: center;
            font-size: 17px;
            border: solid #3cbbed 1.8px;
            border-radius: 10px;
        }

        .select:focus {
            background-color: #3cbbed;
            color: white;
            outline: none;
            border-color: #15214a;
        }
    }

    .formbuttonBack{
        font-size: 15px;
    }

}