/* Preguntas.css */

/* Preguntas.module.css */
#root {
    height: 100%;
}

.accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px; /* Ajusta según sea necesario */
    width: 100%; /* Ajusta según sea necesario */
    margin: auto;
  }
  
  .accordionItem {
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    width: 100%; /* Ajusta según sea necesario */
  }
  
  .accordionSummary {
    cursor: pointer;
    padding: 15px;
    background-color: #3498db; /* Color celeste */
    color: #fff; /* Texto blanco */
    font-weight: bold;
    border-bottom: 1px solid #2980b9;
    transition: background-color 0.3s ease; /* Transición suave para el color de fondo */
  }
  
  .accordionContent {
    margin: 0;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-top: none;
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out; /* Transición suave para la altura */
    background-color: #fff; /* Fondo blanco */
  }
  
  .accordionItem[open] .accordionContent {
    display: block;
    max-height: 100%; /* Ajustar según sea necesario */
  }
  