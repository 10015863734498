.cartContainer{
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-evenly;
    margin: 50px auto;
}

/* CARD CONTAINER */

.cartCardDivContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 70px;
    padding: 0px 20px;
}

.cartCardDivContainer h2{
    text-align: center;
}

.cardCartContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 16px;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.cardCartContainer img{
    height: 130px;
    width: 130px;
}

.cardCartContainer .link{
    color: #3cbbed;
    font-size: 27px;
    width: 100%;
    margin-left: 15px;
}

/* DATOS CART Product*/

.divDatosCart{
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #656569;
}

.infoDetail{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.infoDetail h2{
    font-size: 13px;
    padding: 3px 7px;
    font-weight: 500;
}

.infoDetail:nth-child(2) h2:nth-child(2){
    border: 1px solid black;
}

/* EDITAR */

.editarCtdadDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.editarCtdadDiv button{
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editarCtdadDiv button:disabled{
    cursor: not-allowed;
    position: relative;
}

.editarCtdadDiv button:disabled::before{
    position: absolute;
    content: "maximo en stock";
    color: red;
    top: 180%;
    left: -330%;
    width: 500%;
}

.editarCtdadDiv ion-icon{
    color: #3cbbed;
    font-size: 20px;
}

/* RESUMEN */

.containerResumen{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
}

.divResumen{
    background-color: #3cbbed30;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 400px;
    min-width: 300px;
}

.divResumen h2{
    font-size: 16px;
    border-bottom: 1px solid rgb(62, 62, 62);
    padding-bottom: 5px;
}


/* BUTTON RESUMEN */

.divButton{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
}

.divButton .button1 {
    border: none;
    font-size: 23px;
    padding: 4px 13px 4px 13px;
    border-radius: 50px;
    cursor: pointer;
    color: white;
    position: relative;
    color: white;
    transition: 0.2s;
    border: 1px solid #3cbbed;
    color: #3cbbed;
    background-color: transparent;
    transition: 0.3s;
    overflow: hidden;
    z-index: 99999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.divButton button:disabled{
    cursor: not-allowed;
}

.divButton button:hover {
    color: white;
}

.divButton button::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: #3cbbed;
    z-index: -1;
    transition: 0.6s;
    opacity: 0.5;
}

.divButton button:hover::before {
    width: 100%;
    opacity: 1;
}

.divButton span{
    color: rgb(255, 191, 0);
    font-weight: bold;
}

/* DISABLED */

.divButton button:disabled:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0%;
    left: 0;
    border-radius: 50px;
    background-color: rgb(128, 128, 128);
    z-index: -1;
    transition: 0.6s;
    opacity: 0.5;
}

.divButton button:disabled:hover::before {
    width: 100%;
    opacity: 1;
}

/* DELETE */

.divDelete{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.divDelete ion-icon{
    color: red;
    font-size: 20px;
}

/* EMPTY CART */

.divEmptyCart{
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}

.divLinkToProducts{
    width: 35%;
    margin: auto;
}

.divLinkToProducts a{
    color: #3cbbed;
    transition: 0.4s;
}

.divLinkToProducts a:hover{
    color: #1d8ab5;
}

@media (max-width: 1100px) {
    .cardCartContainer .link{
        color: #3cbbed;
        font-size: 20px;
        width: 100%;
        margin-left: 15px;
    }

    .span{
        font-size: 13px;
    }

    .divResumen {
        min-width: 0px;
    }
} 


@media (max-width: 800px) {

    .cardCartContainer .link{
        color: #3cbbed;
        font-size: 13px;
        width: 100%;
        margin-left: 15px;
    }

    .divResumen{
        align-items: center;
    }

    .cartContainer{
        
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .cartCardDivContainer{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px 0px;
    }

    .cardCartContainer{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 98%;
        border-radius: 16px;
        padding: 0px 10px;
        gap: 3px;
        height: fit-content;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .cardCartContainer h1{
        color: #3cbbed;
        font-size: 12px;
        width: fit-content;
    }


    .infoDetail h2{
        font-size: 11px;
        padding: 3px 7px 3px 7px;
    }

    .containerResumen{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .divEmptyCart{
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: auto;
        justify-content: center;
        align-items: center;
    }

    .divLinkToProducts{
        width: 100%;
        margin: auto;
    }
    .cardCartContainer h1{
        color: #3cbbed;
        font-size: 27px;
        width: 200px;
    }
}

@media (max-width: 525px) {
    .cardCartContainer img{
        height: 60px;
        width: 60px;
    }

    .cardCartContainer .link{
        color: #3cbbed;
        font-size: 11px;
        width: 100%;
        margin-left: 10px;
    }

    .infoDetail h2 {
        font-size: 10px;
    }

    .divDelete ion-icon{
        color: red;
        font-size: 10px;
    }
}