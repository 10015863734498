.global-container {
  align-content: center;
  background-color:transparent
}

.cabecera {
  width: 100%;
  min-height: 90px;
  height: auto;
  background-color: transparent;
  align-content: center;
}

.titulo-cabecera1 {
  padding-top: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.slogan-cabecera {
  float: right;
  font-size: 32px;
  color: white;
  margin: 15px;
}

.imgNos{
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.nosotros-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  background-color: #fffdfd;
  /*      border-top: 1px solid #0093d9; 
     border-bottom: 1px solid #0093d9;  */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ;
}

.titulo-seccion {
  font-size: 2em;
  color: #3cbbed;
  margin-bottom: 10px;
}

.texto-importante {
  font-size: 1.0em;
  line-height: 1.6;
}

.texto-importante h4 {
  color: #0093d9;
  font-size: 25px;
  margin: 15px;
}

.mision-vision-razon {
  margin-bottom: 30px;
  font-size: 1.0em;
  line-height: 1.6;
}

.mision-vision-razon h4 {
  font-size: 1.4em;
  color: #0093d9;
  margin-bottom: 10px;
}

.mision-vision-razon p {
  font-size: 1.2em;
  line-height: 1.6;
}

.valores {
  font-size: 1.2em;
  line-height: 1.6;
  padding: 0;
  margin: 0;
}

.valores li {
  font-size: 1em;
  line-height: 1.6;
  color: #121212;
  margin-bottom: 10px;
}

.valores li:before {
  content: "•";
  color: #111010;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.valores li:first-child {
  margin-top: 10px;
}

.imagen-derecha {
  float: right;
  margin-left: 20px;
  max-width: 50%;

  animation: reveal linear both;
  animation-timeline: view();
  animation-range: 0 400px ;
}

.imagen-izquierda {
  float: left;
  margin-right: 20px;
  max-width: 50%;

  animation: reveal linear both;
  animation-timeline: view();
  animation-range: 0 400px ;
}





@keyframes reveal{
  from{
      scale: 0;
      opacity: 0;
  }
  to {
      scale: 1;
      opacity: 1;
  }
}