.divNavBar {
    position: relative;
    display: flex;
    height: 110px;
    padding:15px 10px;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 10;
}



/* BUTTONS */

.divButtonsNav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
}

.link{
    all: initial;
}

.offBoton {
    border: none;
    font-size: 15px;
    padding: 3px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s, transform 0.3s;
    color: #202549;
    background-color: transparent;
    font-family: "Gotham", sans-serif;
    text-align: left;
    font-weight: 500; /* Establecer el peso de la fuente como normal */
}

.offBoton:hover {
    color: #3cbbed;
    transform: scale(1.1); /* Ajustar la escala al hacer hover */
}

.onBoton {
    border: none;
    font-size: 17px;
    cursor: pointer;
    position: relative;
    color: #3cbbed;
    background-color: transparent;
    transition: color 0.3s, transform 0.3s;
    font-family: "Gotham", sans-serif;
    font-weight: bold; /* Establecer el peso de la fuente como negrita */
}

.onBoton:hover {
    color: #3cbbed;
    transform: scale(1.1); /* Ajustar la escala al hacer hover */
}



/* INPUT SEARCH */

.searchBarDiv input {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 12px;
    border-radius: 20px;
    background: transparent;
    border: 1px solid #3cbbed;
}

.searchBarDiv input:focus {
    outline: none;
    border: 2px solid #3cbbed;
}

.searchBarDiv {
    display: flex;
    gap: 9px;
    justify-content: center;
    align-items: center;
}

.searchBarDiv button {
    background-color: #3cbbed;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: none;
    padding: 7px;
    cursor: pointer;
}

.searchBarDiv button:disabled {
    cursor: not-allowed;
}

.searchBarDiv button h5 {
    font-family: 'Gotham Black', sans-serif;
}

.searchBarDiv button ion-icon {
    font-size: 16px;
}


/* Carrito */

.carritoDiv {
    position: relative;
    cursor: pointer;
}

.carritoDiv ion-icon {
    color: black;
    font-size: 36px;
}

.cartCounter {
    background-color: #3cbbed;
    border-radius: 50%;
    height: 60%;
    width: 60%;
    position: absolute;
    top: 60%;
    left: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* MENU DESPLEGABLE LOG IN LETTER */


.containerLogIn {
    position: relative;
}

.divMenuDesplegable {
    position: absolute;
    height: 120px;
    width: 150px;
    background-color: #202549;
    right: -150%;
    z-index: 50;
    border: 2px solid #3cbbed;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    padding: 0px 10px;
}

.divMenuDesplegable ion-icon {
    font-size: 20px;
}

.divMenuDesplegable h5 {
    margin: 0;
    color: #3cbbed;
}

.linka{
    all: initial;
    /* Opcional: establecer algunos estilos básicos si es necesario */
    text-decoration: none; /* Eliminar subrayado de enlace */
    color: inherit; 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divMenuDesplegable button {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    color: #3cbbed;
    transition: color 0.3s, background-color 0.3s;
    background-color: white;
    border: 2px solid #3cbbed;
    font-family: "Gotham", sans-serif;
}

.divMenuDesplegable button:hover {
    color: white;
    background-color: #3cbbed;
}

.divMenuDesplegable button h5 {
    /*padding: 5px;*/
    font-size: 12.5px;
    text-align: start;
    margin: 0;
    color: #3cbbed;
}

.divMenuDesplegable button:hover h5 {
    /*padding: 5px;*/
    font-size: 12.5px;
    text-align: start;
    margin: 0;
    color: white;
}


.userLetter {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background: #202549;
    color: white;
    font-size: 20px;
    position: relative;
    transition: 0.6s;
    overflow: hidden;
    z-index: 99999999999;
    font-family: 'Gotham Black', sans-serif;
    cursor: pointer;
}

.userLetter:hover {
    background-color: transparent;
}

.userLetter::before {
    position: absolute;
    content: "";
    top: -20%;
    left: 0%;
    background-color: #3cbbed;
    height: 10%;
    width: 0%;
    border-radius: 50%;
    z-index: -1;
    transition: 0.6s;
    opacity: 0;
}

.userLetter:hover::before {
    width: 150%;
    height: 150%;
    opacity: 1;
}


.botonInicio {
    border: none;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    color: #3cbbed;
    background-color: transparent;
    border: 2px solid #3cbbed;
    transition: 0.3s;
    font-family: "Gotham", sans-serif;
    text-align: left;
}

.botonInicio:hover {
    color: white;
    background-color: #3cbbed;
}

@media screen and (max-width: 1200px){

    .divNavBar{
        justify-content: space-between;
    }

    .logo{
        height: 50px;
    }

    .offBoton {
        font-size: 12px;
        padding: 3px; /* Ajusta el espaciado interno según sea necesario */
        font-weight: normal; /* Establece el peso de la fuente a normal para quitar la negrita */
        transition: none;
    }
    .onBoton{
        font-size: 14px;
        font-weight: 500;
    }

    .userLetter{
        height:30px;
        width: 30px;
    }

    .userLetter h4 {
        font-size: 15px;
    }

    .searchBarDiv input {
        gap: none;
        padding: 5px;
    }

    .divButtonsNav{
        gap: 20px;
    }
}



@media(min-width: 820px) {
    .resNavBar {
        display: none;
    }
}


/* ///////        RESPONSIVE MOBILE          ///////// */



@media (max-width: 820px) {

    /* NAVBAR */
    .divNavBar {
        display: none;
    }

    .resNavBar {
        display: flex;
        padding: 10px;
        gap: 5px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 3;
    }

    .resNavBarNoSearch {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .resNavBarNoSearch img {
        width: 170px;
    }

    /* searchbar */

    .resSearchBarDiv input {
        display: flex;
        width: 270px;
        padding: 4px;
        align-items: center;
        gap: 12px;
        border-radius: 20px;
        background: transparent;
        border: 1px solid #3cbbed;
    }

    .resSearchBarDiv input:focus {
        outline: none;
        border: 2px solid #3cbbed;
    }

    .resSearchBarDiv {
        display: flex;
        gap: 9px;
        justify-content: center;
        align-items: center;
    }

    .resSearchBarDiv button {
        background-color: #3cbbed;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        border: none;
        padding: 2px;
        cursor: pointer;
    }

    .resSearchBarDiv button:disabled {
        cursor: not-allowed;
    }

    .resSearchBarDiv button h5 {
        font-family: 'Gotham Black', sans-serif;
    }

    .resSearchBarDiv button ion-icon {
        font-size: 16px;
    }

    /* BUTTON */

    .buttonShowMenu {
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10%;
        padding: 3px;
        transition: 0.3s;
    }

    .buttonShowMenu ion-icon {
        font-size: 25px;
    }

    .actived {
        background-color: #86c4dc;
        border-radius: 50%;
        padding: 3px;
    }

    .actived ion-icon {
        font-size: 25px;
    }

    /* LOGO */

    .resLogo {
        height: 60px;
        width: 180px;
    }

    /* CARRITO */

    .carritoDiv {
        position: relative;
        cursor: pointer;
    }

    .carritoDiv ion-icon {
        color: black;
        font-size: 29px;
    }

    .cartCounter {
        background-color: #3cbbed;
        border-radius: 50%;
        height: 60%;
        width: 60%;
        position: absolute;
        top: 60%;
        left: 0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    /* DISPLAY NAV */

    .menuDisplay {
        position: absolute;
        top: 0%;
        right: 0px;
        width: 0%;
        display: flex;
        justify-content: start;
        flex-direction: column;
        padding: 0;
        transition: 0.3s;
        opacity: 1;
        height: 100%;
        list-style-type: none;
        z-index: 9999;
        margin-top: 0;
        padding: 10px;
    }

    .menuDisplay li {
        display: none;
    }

    .menuDisplay .resUserLetter {
        display: none;
    }

    .menuDisplay .divResLogin {
        display: none;
    }

    .menuDisplay .logInButtons{
        display: none;
    }

    .show {
        position: fixed;
        opacity: 1;
        width: 45%;
        min-height: 100vh;
        height: auto;
        background-color: #3cbbed;
        z-index: 9999;
        margin-top: 0;
        list-style-type: none;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
    }

    .show .resUserLetter {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 50%;
        background: #202549;
        color: white;
        font-size: 20px;
        position: relative;
        transition: 0.6s;
        overflow: hidden;
        z-index: 99999999999;
        font-family: 'Gotham Black', sans-serif;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }

    .show li {
        display: block;
    }

    .show .divResLogin {
        width: 100%;
        margin-top: 10%;
        gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .show .divImgResNavBar{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .show .divResLogin .botonInicio {
        background-color: aliceblue;
        transform: scale(1.3);
    }

    /* NAVIGATIONS BUTTONS */

    .menuDisplay .divButtonsNav {
        display: none;
    }

    .show .divButtonsNav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 70%;
        margin-bottom: 50px;
    }

    .desactivatedA{
        background-color: rgba(255, 255, 255, 0.848);
    }

    .activatedA{
        background-color: rgba(255, 255, 255, 0.274);
    }

    .show .divButtonsNav a{
        width: 100%;
        padding: 5px 0px 5px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
    }

    .show .divButtonsNav a button{
        color: #3cbbed;
    }

    .show .divButtonsNav .onBoton{
        color: #ffffff;
    }
    
    .show h5{
        margin: 0;
        font-size: 16px;
    }

    .show .logInButtons{
        margin-top: 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 25px;
        border-bottom: 1px solid black;
        padding-bottom: 20px;
    }

    .logInButtons h6{
        margin: 0;
        font-size: 17px;
    }

    .show .logInButtons a{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .show .logInButtons a button{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0px 5px 0px;
    }

    .show .logInButtons button{
        border-radius: 16px;
        background-color: white;
        border: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .buttonLogOut{
        color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5px 0px 5px 0px;
    }

    .buttonMiPerfil{
        color:#3cbbed
    }

    .imgLog {
        filter: drop-shadow(1px 1px 4px white);
    }
}