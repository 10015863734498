.divMain {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 300px;
  padding: 5px;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-direction: column; /* Alineación vertical */
}

/* Estilos comunes para ambos tipos de botones */
.offBoton,
.onBoton {
  border: none;
  font-size: 14px;
  padding: 10px 10px 10px 40px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  font-family: "Gotham", sans-serif;
  text-align: left;
  margin: 5px 0px;
}

/* Estilos para botones desactivados (.offBoton) */
.offBoton {
  color: #3cbbed;
  background-color: transparent;
}

.offBoton:hover {
  color: white; /* Cambia el color de la letra a blanco al hacer hover */
}

.offBoton::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0%;
  top: 0%;
  left: 0;
  border-radius: 20px;
  background-color: rgba(60, 187, 237, 0.50);
  z-index: -1;
  transition: width 0.6s, opacity 0.6s; /* Aplica la transición a ambas propiedades */
  opacity: 0;
}

.offBoton:hover::before {
  width: 100%;
  opacity: 1;
}

/* Estilos para botones activados (.onBoton) */
.onBoton {
  color: white;
  background-color: #3cbbed;
}

.onBoton .logoboton {
  filter: brightness(50);
  cursor: pointer;
}

.selectOrden {
  font-family: "Gotham", sans-serif;
  background-color: white;
  height: auto;
  padding: 4px;
  color: #3cbbed;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  border: solid #3cbbed 1.8px;
  border-radius: 10px;
  width: 100%;
}
.selectOrden:focus {
  background-color: #3cbbed;
  color: white;
  outline: none; 
  border-color: #15214a;
}

.titulo {
  border: none;
  font-size: 15px;
  padding: 8px 28px 8px 28px;
  color: #15214a;
  transition: 0.2s;
  transition: 0.3s;
  font-family: "Gotham", sans-serif;
  width: auto;
}


.ordenadores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 200px;
}







@media screen and (max-width: 1042px) {
  .divMain{
    width: auto;
  }

  .buttonContainer {
    flex-direction: row; /* Alineación vertical */
    flex-wrap: wrap;
  }

  .offBoton,
  .onBoton {
    border: none;
    font-size: 12px;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    font-family: "Gotham", sans-serif;
    text-align: left;
    margin: 5px 0px;
  }

  .selectOrden {
    font-family: "Gotham", sans-serif;
    background-color: white;
    height: auto;
    padding: 4px;
    color: #3cbbed;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    border: solid #3cbbed 1.8px;
    border-radius: 10px;
    width: 100%;
  }

  .query{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .titulo {
    border: none;
    font-size: 14px;
    padding: 8px 28px 8px 28px;
    color: #15214a;
    font-family: "Gotham", sans-serif;
  }
}



@media screen and (max-width: 500px) {

  .query{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .ordenadores {
    width: 150px;
  }

  .titulo{
    font-size: 11px;
  }

  .offBoton,
  .onBoton {
    border: none;
    font-size: 10px;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    font-family: "Gotham", sans-serif;
    text-align: left;
    margin: 5px 0px;
  }

  .selectOrden {
    font-family: "Gotham", sans-serif;
    background-color: white;
    height: auto;
    padding: 4px;
    color: #3cbbed;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    border: solid #3cbbed 1.8px;
    border-radius: 10px;
    width: 100%;
  }
}