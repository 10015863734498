
.divPrinp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.label{
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;

    font-size: 14px;
    color: #15214a;
    font-family: "Gotham", sans-serif;
    font-weight: 600;
}

.select {
    font-family: "Gotham", sans-serif;
    background-color: white;
    height: auto;
    padding: 4px;
    color: #3cbbed;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    border: solid #3cbbed 1.8px;
    border-radius: 10px;
    width: 100%;
}

.select:focus {
    background-color: #3cbbed;
    color: white;
    outline: none; 
    border-color: #15214a;
  }