@tailwind base;
@tailwind components;
@tailwind utilities;

body {  
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbfb;
  font-family: 'Gotham', sans-serif;
  min-height: 100vh;
}

body, html {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

*{
  scroll-behavior: smooth;
  box-sizing: border-box;
}

a{
  text-decoration: none;
}