/* .contact{
  display: flex;
  background: #3cbbed;
  height: auto;
  color: aliceblue;
  flex-direction: column;
  justify-content: center;
  font-size: small;
}

.tabla{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: left;
  gap: 200px;
  line-height: 30px;
  font-size: 15px;
  font-weight: 500;
}

.footer {
  display: flex;
  background: black;
  line-height: 40px;
  justify-content: center;
  gap: 5px;
  text-decoration: none;
  color: aliceblue;
}

a {
  text-decoration: none;
  color: aliceblue;
} */


/* INSTAGRAM */
/* .divInstagram{
  display: flex;
  justify-content: flex-start;
  align-items: left;
  gap: 10px;
}

.divInstagram ion-icon{
  font-size: 20px;
} */

/* PUNTOS DE VENTA */

.puntosDeVentaDiv{
  display: flex;
  align-items: center;
  gap: 10px;
}

.puntosDeVentaDiv ion-icon{
  font-size: 20px;
}

/* INFO DIV */

/* .infoDiv{
  display: flex;
  align-items: center;
  gap: 10px;
}

.infoDiv ion-icon{
  font-size: 20px;
}
 */
.footer{
  background-color: #3cbbed;
}

 .section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: aliceblue;
  padding: 20px;
  
}

.footerSection {
  font-weight: 500;
  margin: 0 15px;
  padding: 15px; 
}

.divRed {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-direction: column;
  font-size: 18px;
}

.divRed ion-icon {
  font-size: 18px;
}

.divRed a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  gap: 5px;
}


.infoDiv {
  display: flex;
  gap: 10px;
  align-items: center;
}

.infoDiv ion-icon {
  font-size: 20px;
}

.link {
  text-decoration: none;
  color: aliceblue;
}
.a {
    display: contents;
    text-decoration: none;
    color: white; /* Opcional: Para heredar el color del texto del elemento padre */
    margin: 15px 0px;
}

.li {
  margin: 15px 0px;

}


.divFinal{
  background-color: #d6d6d6;
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
  height: 25px;
  .link{
    font-size: 13px;
    color: #383838;
  }
}

/* Estilos generales para dispositivos móviles */
@media only screen and (max-width: 767px) {
  .section{
    justify-content:start;
  }
}

@media only screen and (max-width: 580px) {
  .divFinal{
    background-color: #d6d6d6;
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    height: 25px;
    .link{
      font-size: 8px;
      color: #383838;
    }
  }
}
